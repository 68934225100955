import React from "react";
import { Box, Flex, Image, Link, Text } from "@chakra-ui/react";
import { footerLinks, footerSubLinks, socials } from "../common/constants";

const Footer = () => {
  const today = new Date();
  return (
    <Flex
      justifyContent="center"
      bg="dark"
      className="full_width"
      mt={{ base: "50px", md: "106px" }}
      color="#fff"
      fontFamily="Poppins"
    >
      <Box
        w={{ base: "100", md: "1326px" }}
        p={{ base: "56px 0", md: "56px 61px 75px 100px" }}
      >
        <Flex
          mb="72px"
          display={{ base: "none", md: "flex" }}
          justifyContent="center"
        >
          <Flex
            align="center"
            flexDir={{ base: "column", md: "row" }}
            gap="48px"
          >
            {footerLinks.map((item, i) => (
              <Link href={item?.id} smooth={true} duration={500}>
                <Text key={i} color="#fff" lineHeight="24px">
                  {item.name}
                </Text>
              </Link>
            ))}
          </Flex>
        </Flex>

        <Flex
          pt={{ base: "40px", md: "100px" }}
          flexDir={{ base: "column", md: "row" }}
          justifyContent="space-between"
          gap={{ base: "50px", md: "unset" }}
        >
          <Flex flexDir="column" align={{ base: "center", md: "flex-start" }}>
            <Image
              src="/assets/dark-logo.png"
              ml="-25px"
              h="39.3px"
              objectFit="contain"
            />
            <Text
              mt={{ base: "41px", md: "18.7px" }}
              mb={{ base: "11px", md: "20px" }}
              color="#fff"
              fontSize={{ base: "12px", md: "14px" }}
              lineHeight={{ base: "16.8px", md: "19.6px" }}
            >
              Available on
            </Text>

            <Flex align="center" gap="16px">
              <Flex
                bg="#1A1C1F"
                borderRadius="8px"
                gap="8px"
                align="center"
                p="16px"
              >
                <Box w="24px" h="24px">
                  <Image
                    src="/assets/android.svg"
                    w="24px"
                    h="24px"
                    objectFit="contain"
                  />
                </Box>
                <Text color="#fff" fontSize="15px" lineHeight="21px">
                  Android
                </Text>
              </Flex>

              <Flex
                bg="#1A1C1F"
                borderRadius="8px"
                gap="8px"
                align="center"
                p="16px"
              >
                <Box w="24px" h="24px">
                  <Image
                    src="/assets/apple.svg"
                    w="24px"
                    h="24px"
                    objectFit="contain"
                  />
                </Box>
                <Text color="#fff" fontSize="15px" lineHeight="21px">
                  iOS
                </Text>
              </Flex>
            </Flex>
          </Flex>

          <Box textAlign={{ base: "center", md: "end" }}>
            <Flex
              align="center"
              gap="12px"
              justifyContent={{ base: "center", md: "flex-end" }}
            >
              {socials.map((item, i) => (
                <Flex
                  key={i}
                  bg="#2463EB"
                  rounded="full"
                  w="32px"
                  h="32px"
                  justifyContent="center"
                  align="center"
                >
                  <a href={item?.link} target="_blank" rel="noreferrer">
                    <Image
                      src={item?.img}
                      w="16px"
                      h="16px"
                      objectFit="contain"
                    />
                  </a>
                </Flex>
              ))}
            </Flex>

            <Text
              my="16px"
              color="#fff"
              fontSize={{ base: "12px", md: "14px" }}
              lineHeight={{ base: "18px", md: "21px" }}
            >
              <span style={{ fontWeight: 600 }}> Email:</span>{" "}
              support@bizflex.africa
            </Text>
            <Text
              color="#fff"
              fontSize={{ base: "12px", md: "14px" }}
              lineHeight={{ base: "18px", md: "21px" }}
            >
              <span style={{ fontWeight: 600 }}> Phone:</span> 02012296204
            </Text>

            <Flex
              mt="16px"
              align={{ base: "center", md: "flex-start" }}
              gap="7px"
              fontSize={{ base: "12px", md: "14px" }}
              lineHeight={{ base: "18px", md: "21px" }}
              color="#fff"
              flexDir={{ base: "column", md: "row" }}
              justifyContent={{ base: "flex-start", md: "flex-end" }}
              pb={{ base: "28px", md: "unset" }}
              borderBottom={{ base: "2px solid #fff", md: "none" }}
            >
              <Text fontWeight={600}>Address:</Text>
              <Text w={{ base: "70%", md: "40%" }}>
                4th Floor, Polystar Tower, 128, Remi Olowude Street, Lekki Phase
                1, Lagos
              </Text>
            </Flex>

            <Flex
              mt="16px"
              align={{ base: "center", md: "flex-start" }}
              gap="7px"
              fontSize={{ base: "12px", md: "14px" }}
              lineHeight={{ base: "18px", md: "21px" }}
              color="#fff"
              flexDir={{ base: "column", md: "row" }}
              justifyContent={{ base: "flex-start", md: "flex-end" }}
            >
              <Text w={{ base: "90%", md: "70%" }}>
                BizFlex is a financial technology product offered by FastaMoni
                Technologies Limited. BizFlex is not a bank; all banking
                services are provided by our licensed banking partners.
              </Text>
            </Flex>

            <Flex justifyContent={{ base: "center", md: "flex-end" }} gap={5}>
              {footerSubLinks.map((item, i) => (
                <Link key={i} href={item.id}>
                  <Text
                    mt={{ base: "50px", md: "20px" }}
                    mb="10px"
                    color="#646060"
                    fontSize={{ base: "12px", md: "16px" }}
                    w={{ base: "70%", md: "unset" }}
                    lineHeight={{ base: "19.68px", md: "24px" }}
                    key={i}
                  >
                    {item.name}
                  </Text>
                </Link>
              ))}
            </Flex>
            <Text
              color="#646060"
              fontSize={{ base: "10px", md: "16px" }}
              lineHeight={{ base: "15px", md: "24px" }}
            >
              © {today.getFullYear()} Fastamoni. All rights reserved.
            </Text>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};

export default Footer;
